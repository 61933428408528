<template>
	<div class="aboutUs">
		<h1>品牌简介</h1>
		<p class="brief" v-for="(item,index) in data.brief" :key="index">{{item}} </p>
		<h2>核心产品</h2>
        <p class="msg">
           {{data.task}}
        </p>
        <h2>产品介绍 </h2>
        <p class="msg">
           {{data.companyBrief}}
        </p>
          <h2>产品特色 </h2>
        <p class="msg">
           {{data.advantage}}
        </p>
        <h2>产品科技 </h2>
        <p class="msg">
           {{data.technology}}
        </p>
        <h2>教学顾问</h2>
		<p class="msg">
			<ul v-for="(item, idx) in data.notes" :key="idx">
				<li> {{item}}</li>
			</ul>
		</p>
         <h2>线下活动</h2>
        <p class="msg">
            <ul>
                <li v-for="(item,index) in data.invest" :key="index" class="activity" >{{item.name}}
                    <div class="links" @click="goto(item.url)">查看活动详情》</div>
                </li>

            </ul>

        </p>
	</div>
</template>
<script>
export default {
	name: 'aboutUs',
	data () {
		return {
			data:{}
		}
	},
    created(){
        this.getData()
    },
    methods:{
        getData(){
            this.axios.get('https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/brand.json').then(res=>{
           this.data=res.data
         })
        },
        goto(url){
            window.open(url,"_blank")
        }
        
    }
}
</script>
<style lang="scss" scoped>
.aboutUs{
    text-align: left;
    padding: .853rem .493rem;
    
    h1{
        font-size: .533rem;
        color:#444144;
        font-weight: bold;
    }
    .brief{
        font-size: .347rem;
        color: #444144;
        line-height: .533rem;
        text-indent:2em;
        margin: .36rem 0 .4rem;
    }
    h2{
        color: #FF7041;
        font-size: .427rem;
        font-weight: bold;
    }
    .msg{
        font-size: .347rem;
        color: #444144;
        line-height: .533rem;
        margin: .227rem 0 .507rem;
    }
    .msg li span{
        font-weight: bold;
        margin-right: .267rem;
    }
    .msg p{
            text-indent: .267rem;
    }
    .links{
        color:#3385ff ;
        &:hover{
            cursor: pointer;
        }
    }
    .activity{
        margin-bottom: .4rem;
    }
}
</style>
